const BlogList = ({ blogs, header }) => {
  // const blogs = props.blogs;
  // const title = props.title;
  // console.log(blogs);

  return (
    <div className="blog-list">
      <h2>{ header }</h2>
      {blogs.map(blog => (
        <div className="blog-preview" key={blog.id} >
          <h2>{ blog.title }</h2>
          <p>Written by { blog.author }</p>
          <p>{ blog.body }</p>
          <p>{ blog.age }</p>
        </div>
      ))}
    </div>
  );
}
 
export default BlogList;
